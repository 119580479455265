import React from 'react';
import SignupForm from '../components/signupform';

const SignupPage = () => {
  return (
    <div>
      <SignupForm />
    </div>
  );
};

export default SignupPage;
